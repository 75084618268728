const whitelistEmails = [
  "jeremy.kirkham@equiem.com.au",
  "ryan.ghanbari@getequiem.com",
  "rinita.sur@getequiem.com",
  "jen.leibhart@getequiem.com",
  "faraz.ahmad@getequiem.com",
  "arun.thenabadu@equiem.com.au",
  "marley.fabisiewicz@getequiem.com",
  "julia.bearzatto@getequiem.com",
  "gabrielle.mcmillan@getequiem.com",
  "sarah.machon@getequiem.com",
  "katy.austin@getequiem.com",
  "james.harvey@getequiem.com",
  // CWG internal team:
  "Michelle.Laramy@CanaryWharf.com",
  "Claire.Slater@CanaryWharf.com",
  "Francesca.Clemens@CanaryWharf.com",
  "Jade.Forrester@CanaryWharf.com",
  "Jessica.Pinkham@CanaryWharf.com",
  "Pippa.Dale@CanaryWharf.com",
  "Sophie@ThisIsVertus.com",
  "Steve.Pritchard@CanaryWharf.com",
  "Pete.Ayertey-Wilson@CanaryWharf.com",
  "Steven.Gale@CanaryWharf.com",
  "Adam.Robinson@CanaryWharf.com",
  "Will.Griggs-Leslie@CanaryWharf.com",
  "Amy.Oudot@CanaryWharf.com",
  "Natalie.Marcus@canarywharf.com",
];

export const useShowAi = (email?: string) => {
  return email != null && whitelistEmails.map((e) => e.toLowerCase()).includes(email.toLowerCase());
};
